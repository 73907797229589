import React from 'react';
import ComponentWrapper from '../../layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const BILIARY = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview" 
            description="The efficacy of KEYTRUDA in combination with gemcitabine/cisplatin chemotherapy  was investigated in KEYNOTE-966, a multicenter, randomized, double-blind, placebo-controlled trial that enrolled 1069 patients with locally advanced unresectable or metastatic BTC, who had not received prior systemic therapy in the advanced disease setting. Patients with autoimmune disease that required systemic therapy within 2 years of treatment or a medical condition that required immunosuppression were ineligible. Randomization was stratified by region (Asia vs non-Asia), locally advanced vs metastatic, and site of origin (gallbladder, intrahepatic, or extrahepatic cholangiocarcinoma). Patients were randomized (1:1) to one of the two treatment groups."
        />
        <StudyDesignArms 
            title="Study Arms" 
            arms={[
                {
                    title: "Treatment",
                    data: [
                        [
                            {
                                title: 'KEYTRUDA + gemcitabine/cisplatin',
                                description: `KEYTRUDA 200 mg on Day 1 plus gemcitabine 1000 mg/m<sup>2</sup> and cisplatin 25 mg/m<sup>2</sup> on Day 1 and Day 8 every 3 weeks.`,
                                type: 'box-green'
                            },
                            {
                                title: 'Placebo + gemcitabine/cisplatin',
                                description: `Placebo on Day 1 plus gemcitabine 1000 mg/m<sup>2</sup> and cisplatin 25 mg/m<sup>2</sup> on Day 1 and Day 8 every 3 weeks.`,
                            }
                        ],
                    ]
                }
            ]} 
        />

        <StudyCopyBlock
        description={`
            <ul>
                <li>All study medications were administered via intravenous infusion. Treatment was continued until unacceptable disease toxicity or disease progression.
                    <ul>
                        <li><strong>For KEYTRUDA, treatment was continued for a maximum of 35 cycles as assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.</strong></li>
                        <li><strong>For gemcitabine, treatment could be continued beyond 8 cycles.</strong></li>
                        <li><strong>For cisplatin, treatment could be administered for a maximum of 8 cycles.</strong></li>
                    </ul>
                </li>
                <li>Administration of KEYTRUDA with chemotherapy was permitted beyond RECIST-defined disease progression if the patient was clinically stable and deriving clinical benefit as determined by the investigator.</li>
                <li>Assessment of tumor status was performed at baseline and then every 6 weeks through 54 weeks, followed by every 12 weeks thereafter.</li>
            </ul>
        `}
        />
    
        <StudyCopyBlock 
            title="End Points"
            description="<p>The major efficacy outcome measure was OS.</p>
            <p>Additional efficacy outcome measures were PFS, ORR, and DOR as assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.</p>"
        />
    
        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <p>Baseline Characteristics of Patients (N=1069)</p>
                <p>All patients randomized at baseline:</p>
                <ul>
                    <li>Median age: 64 years (range: 23–85), 47% age 65 or older</li>
                    <li>Male: 52%</li>
                    <li>White: 49%</li>
                    <li>Asian: 46%</li>
                    <li>Black or African American: 1.3%</li>
                    <li>Hispanic or Latino: 10%</li>
                    <li>ECOG PS of 0: 46%; ECOG PS of 1: 54%</li>
                    <li>Hepatitis B infection: 31%</li>
                    <li>Hepatitis C infection: 3%</li>
                </ul>                
            `} 
            definitions="BICR = blinded independent central review; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; ECOG PS = Eastern Cooperative Oncology Group performance status."
        />

    </ComponentWrapper>
);

export default BILIARY;
