import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, ComparisonTable, PatientProfile, ReferencesBlock, EndpointDisplay } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';
import { StaticImage } from 'gatsby-plugin-image';
import BILIARY from '../../components/StudyDesign/__study-design/biliary';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-966 - Clinical Trial Results | HCP`,
    keywords: `keynote-966, clinical trial results for advanced biliary tract cancer`,
    description: `Health care professionals can view clinical trial results for KEYNOTE-966 in certain patients with locally advanced unresectable or metastatic biliary tract cancer (BTC).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-biliary-tract-cancer-combination-therapy/","@type":"MedicalTrial","name":"Clinical Findings from KEYNOTE⁠-⁠966","description":"17% reduction in the risk of death with KEYTRUDA + gemcitabine/cisplatin vs gemcitabine/cisplatin alone","trialDesign":"The efficacy of KEYTRUDA in combination with gemcitabine/cisplatin chemotherapy was investigated in KEYNOTE-966, a multicenter, randomized, double-blind, placebo-controlled trial that enrolled 1069 patients with locally advanced unresectable or metastatic BTC, who had not received prior systemic therapy in the advanced disease setting. Patients with autoimmune disease that required systemic therapy within 2 years of treatment or a medical condition that required immunosuppression were ineligible. Randomization was stratified by region (Asia vs non-Asia), locally advanced vs metastatic, and site of origin (gallbladder, intrahepatic, or extrahepatic cholangiocarcinoma). Patients were randomized (1:1) to one of the two treatment groups."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-biliary-tract-cancer-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."}`,
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-biliary-tract-cancer-combination-therapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with gemcitabine and cisplatin, is indicated for the treatment of patients with locally advanced unresectable or metastatic biliary tract cancer (BTC)."}'
    ]
}

//from /src/util/data/indications.js
const indicationId = 35; 
const jobCode = jobCodes[36].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Related Information",
            items: [
                { text: 'Advanced Esophageal or GEJ Carcinoma', url: '/efficacy/esophageal-carcinoma/' },
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/advanced-biliary-tract-cancer-combination-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;966' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;966' }, 
    { label: 'Patient Profiles' }, 
];

const ComparisonTableFootnotes = [
    {
        label: "a.",
        text: "Results at the pre-specified final OS analysis."
    },
    {
        label: "b.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "c.",
        text: "One-sided <i>P</i> value based on a stratified log-rank test."
    },
    {
        label: "d.",
        text: "Results at pre-specified final analysis of PFS and ORR."
    },
];
const ComparisonTableFootnotes2 = [
    {
        label: "g.",
        text: "Based on Kaplan-Meier estimate."
    },
];

const patientProfileCharles = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-charles.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Charles",
    diagnosis: "Intrahepatic cholangiocarcinoma",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy ',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'Tumor Status',
            value: 'Metastatic',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '64',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
    ],
};

const patientProfileAnne = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-anne.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Anne",
    diagnosis: "Extrahepatic cholangiocarcinoma",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy ',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'Tumor Status',
            value: 'Metastatic',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '71',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
    ],
};

const patientProfileMaria = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-maria.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Maria",
    diagnosis: "Advanced gallbladder cancer",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy ',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '0',
            emphasize: false,
        },
        {
            key: 'Tumor Status',
            value: 'Locally advanced unresectable',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '56',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'High',
        },
    ],
};

const EndpointDisplayFootnotes = [
    {
        label: "e.",
        text: "Confirmed complete response or partial response."
    },
    {
        label: "f.",
        text: "One-sided <i>P</i> value based on the stratified Miettinen and Nurminen analysis."
    },
];

const pageReferences = [
    {
        label: "1.",
        text: "Kelley RK, Ueno M, Yoo C, et al. Pembrolizumab in combination with gemcitabine and cisplatin compared with gemcitabine and cisplatin alone for patients with advanced biliary tract cancer (KEYNOTE&#8288;-&#8288;966): a randomised, double-blind, placebo-controlled, phase 3 trial. <i>Lancet</i>. 2023;401:1853&ndash;1865. doi:10.1016/S0140-6736(23)00727-4"
    },
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                        <AnchorLinks items={anchorLinkModelData} />
                   </PageSection>
                   <PageSection bgColor="gradient"> 
                        <PrimaryClaim theme="light">Superior Overall Survival (OS)<sup>a</sup> With KEYTRUDA + Gemcitabine/Cisplatin as First-Line Treatment vs Gemcitabine/Cisplatin Alone</PrimaryClaim>
                        </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor='light' title={anchorLinkModelData[0].label}>
                                <SecondaryCallout 
                                    title="17% reduction in the risk of death with KEYTRUDA + gemcitabine/cisplatin vs gemcitabine/cisplatin alone"
                                    text="HR<sup>b</sup>=0.83 (95% CI, 0.72&ndash;0.95; <i>P</i><sup>c</sup>=0.0034)"
                                    definitions="CI = confidence interval; HR = hazard ratio."
                                />
                                <ComparisonTable 
                                    title='Overall Survival'
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + gemcitabine/cisplatin (n=533)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + gemcitabine/cisplatin (n=536)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: "HR=0.83 (95% CI, 0.72&ndash;0.95; <i>P</i>=0.0034)"
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '78%',
                                                    dataSubPoint: "(n=414/533)"
                                                },
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '83%',
                                                    dataSubPoint: '(n=443/536)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '12.7 months',
                                                    dataSubPoint: '(95% CI, 11.5&ndash;13.6)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '10.9 months',
                                                    dataSubPoint: '(95% CI, 9.9&ndash;11.6)'
                                                },
                                            ]
                                        ]
                                    } 
                                />
                                <KMCurve 
                                    title='Kaplan-Meier estimates of OS with KEYTRUDA + gemcitabine/cisplatin vs gemcitabine/cisplatin'
                                    hr='HR=0.83 (95% CI, 0.72–0.95; <i>P</i>=0.0034)'
                                    image={() => <StaticImage 
                                        src="../../assets/US-KEB-00029_KM_Curve_OS_Gem_Cis_8 Cycles_v04.png"
                                        placeholder="none"
                                        alt="Kaplan-Meier Estimates of Overall Survival With KEYTRUDA® (pembrolizumab) + gemcitabine/cisplatin vs gemcitabine/cisplatin Alone in Clinical Findings From KEYNOTE-966" />
                                    }  
                                />
                                <ComparisonTable 
                                    limitation='<strong>14% reduction in the risk of disease progression or death with KEYTRUDA + gemcitabine/cisplatin vs gemcitabine/cisplatin alone</strong>'
                                    title='Progression-Free Survival (PFS)<sup>d</sup>'
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + gemcitabine/cisplatin (n=533)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + gemcitabine/cisplatin (n=536)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR=0.86 (95% CI, 0.75&ndash;1.0; <i>P</i>=NS)'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '68%',
                                                    dataSubPoint: "(n=361/533)"
                                                },
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '73%',
                                                    dataSubPoint: "(n=391/536)"
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '6.5 months',
                                                    dataSubPoint: '(95% CI, 5.7&ndash;6.9)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '5.6 months',
                                                    dataSubPoint: '(95% CI, 5.1&ndash;6.6)'
                                                },
                                            ],
                                        ]
                                    } 
                                    definitions="NS = not significant; ORR = Objective Response Rate."
                                    footnotes={ComparisonTableFootnotes} 
                                />
                                <EndpointDisplay 
                                    title='Objective Response Rate (ORR)<sup>d,e</sup>'
                                    footnotes={EndpointDisplayFootnotes}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA + gemcitabine/cisplatin (n=533)',
                                                rate: '29%',
                                                ci: '95% CI, 25–33, <i>P</i><sup>f</sup>=NS',
                                                // p: '<sup>f</sup>=NS',
                                                completeResponseRate: '2.1%',
                                                partialResponseRate: '27%'
                                            
                                            },
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '29%',
                                                ci: '95% CI, 25–33, <i>P</i><sup>f</sup>=NS',
                                                // p: "<sup>f</sup>=NS",
                                                label: 'Placebo + gemcitabine/cisplatin (n=536)',
                                                completeResponseRate: '1.3%',
                                                partialResponseRate: '27%'
                                            },
                                        ]
                                    }
                                />
                                <ComparisonTable 
                                    title='Duration of Response (DOR)<sup>a</sup>'
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + gemcitabine/cisplatin (n=156)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + gemcitabine/cisplatin (n=152)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median DOR<sup>g</sup>',
                                                    dataPoint: '8.3 months',
                                                },
                                                {
                                                    label: 'Median DOR<sup>g</sup>',
                                                    dataPoint: '6.8 months',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Range',
                                                    dataPoint: '6.9 to 10.2 months',
                                                },
                                                {
                                                    label: 'Range',
                                                    dataPoint: '5.7 to 7.1 months',
                                                },
                                            ],
                                        ]
                                    } 
                                    footnotes={ComparisonTableFootnotes2}
                                />
                        </PageSection>

                        <PageSection
                            title={anchorLinkModelData[1].label}
                            bgColor='cloud'
                        >
                            <SecondaryCallout 
                                title="KEYNOTE&#8288;-&#8288;966: A large phase 3 clinical trial in 1069 patients with locally advanced unresectable or metastatic BTC<sup>1</sup>"
                                text=""
                            />
                            <BILIARY />
                        </PageSection>

                        <PageSection
                            title={anchorLinkModelData[2].label}
                        >
                            <PageSubSection
                                title="Hypothetical Patients Based on Those Studied in KEYNOTE&#8288;-&#8288;966"
                            >
                                <PatientProfile {...patientProfileMaria}/>
                                <PatientProfile {...patientProfileCharles}/>
                                <PatientProfile {...patientProfileAnne}/>
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>
                
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
